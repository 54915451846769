import React, { ReactElement } from 'react'
import { Layout, SEO } from 'app/components'
import { Contacts } from 'app/components/contacts/contacts'

const ContactsPage = (): ReactElement => (
  <Layout headerColors={[null, null, 'white', 'white', 'white']}>
    <SEO title='Контакты – Крепость' />
    <Contacts />
  </Layout>
)

export default ContactsPage
