import classNames from 'classnames'
import Image, { FixedObject } from 'gatsby-image'
import React, { ReactElement } from 'react'
import { getTelegramLink, getEmailLink } from 'app/utils'
import { Heading, Paragraph } from '../text'
import './contact.scss'

interface ContactProps {
  imageSrc: FixedObject | FixedObject[]
  name: string
  description: string
  telegram?: string
  email?: string
  className?: string
}

export const Contact = ({
  className,
  imageSrc,
  name,
  description,
  telegram,
  email,
}: ContactProps): ReactElement => (
  <div className={classNames('contact', className)}>
    <Image className='contact__image' fixed={imageSrc} />
    <Heading
      tag='h2'
      size='28'
      align='center'
      marginBottom='0'
      className='contact__heading'
    >
      {name}
    </Heading>
    <Paragraph className='contact__description' size='18' align='center'>
      {description}
    </Paragraph>
    {telegram && (
      <Paragraph size='18' marginBottom='0'>
        <b>Telegram:</b>&nbsp;
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={getTelegramLink(telegram)}
        >
          @{telegram}
        </a>
      </Paragraph>
    )}
    {email && (
      <Paragraph size='18' marginBottom='0'>
        <b>E-mail:</b>&nbsp;
        <a target='_blank' rel='noopener noreferrer' href={getEmailLink(email)}>
          {email}
        </a>
      </Paragraph>
    )}
  </div>
)
