import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import React, { ReactElement, useCallback } from 'react'
import { Contacts as ContactsList } from 'app/constants'
import FormOne from 'app/images/section-four-form-3.svg'
import { getImageSources, getEmailLink } from 'app/utils'
import { Button } from '../button'
import { Heading } from '../text'
import { Contact } from './contact'
import './contacts.scss'

export const Contacts = (): ReactElement => {
  const {
    xlImage,
    lgImage,
    mdImage,
    smImage,
    xsImage,
    svetov,
    ald,
    larikova,
    sahnova,
  } = useStaticQuery(graphql`
    query {
      xlImage: file(relativePath: { eq: "backgrounds/fortress-two-xl.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      lgImage: file(relativePath: { eq: "backgrounds/fortress-two-lg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      mdImage: file(relativePath: { eq: "backgrounds/fortress-two-md.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      smImage: file(relativePath: { eq: "backgrounds/fortress-two-sm.png" }) {
        childImageSharp {
          fluid(maxWidth: 768, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      xsImage: file(relativePath: { eq: "backgrounds/fortress-two-xs.png" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      svetov: file(relativePath: { eq: "persons/svetov.png" }) {
        childImageSharp {
          fixed(width: 176, height: 176, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      sahnova: file(relativePath: { eq: "persons/sahnova.png" }) {
        childImageSharp {
          fixed(width: 176, height: 176, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      larikova: file(relativePath: { eq: "persons/larikova.png" }) {
        childImageSharp {
          fixed(width: 176, height: 176, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      ald: file(relativePath: { eq: "persons/ald.png" }) {
        childImageSharp {
          fixed(width: 176, height: 176, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  const handleOnEmail = useCallback(() => {
    window.location.href = getEmailLink(ContactsList.EMAIL)
  }, [])

  const sources = getImageSources({
    type: 'fluid',
    xsImage,
    smImage,
    mdImage,
    lgImage,
    xlImage,
  })
  return (
    <section className='contacts'>
      <div className='contacts__form'>
        <FormOne />
      </div>
      <Image
        className='contacts__background'
        fluid={sources}
        imgStyle={{ objectPosition: '50% 100%', height: 'auto' }}
        loading='eager'
      />
      <Heading size='48' align='center' className='contacts__heading'>
        Контакты
      </Heading>
      <div className='contacts__container'>
        <Contact
          className='contacts__svetov'
          imageSrc={svetov.childImageSharp.fixed}
          name='Михаил Светов'
          description='Председатель Гражданского общества'
          telegram={ContactsList.SVETOV_TELEGRAM}
          email={ContactsList.SVETOV_EMAIL}
        />
        <Contact
          imageSrc={ald.childImageSharp.fixed}
          name='Адда Альд'
          description='Участница Гражданского общества, инициатор и менеджер проекта'
          telegram={ContactsList.ALD_TELEGRAM}
        />
        <Contact
          imageSrc={larikova.childImageSharp.fixed}
          name='Людмила Ларикова'
          description='Участница Гражданского общества, менеджер проекта и активистка'
        />
        <Contact
          imageSrc={sahnova.childImageSharp.fixed}
          name='Евгения Сахнова'
          description='Участница Гражданского общества, менеджер проекта и активистка'
        />
      </div>
      <Button color='blue' className='contacts__email' onClick={handleOnEmail}>
        {ContactsList.EMAIL}
      </Button>
    </section>
  )
}
